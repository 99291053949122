<template>
  <section id="content">
    <PageTitle :title="$t('profile.title')"></PageTitle>
    <div class="wrapper wrapper-content animated fadeInRight">

      <div class="row">
        <div class="col-md-4">
          <div class="ibox">
            <div class="ibox-title">
              <h5>{{ $t('profile.account.title') }}</h5>
            </div>
            <div class="ibox-content">
      
                <div v-if="user.login != user.email" class="form-group row">
                    <div class="col-lg-3">{{ $t('profile.account.login') }}</div>
                    <div class="col-lg-9">
                      {{ user.login }}
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-3">{{ $t('profile.account.email') }}</div>
                    <div class="col-lg-9">
                      {{ user.email }}
                    </div>
                </div>
                <button v-if="isUpdateUserAllowed" class="btn btn-primary ladda-button" data-style="zoom-in" type="button" @click="onUpdateUserButtonClick">{{ $t('profile.account.update') }}</button>
                <button v-if="isUpdatePasswordAllowed" class="btn btn-primary ladda-button" data-style="zoom-in" type="button" @click="onUpdatePasswordButtonClick">{{ $t('profile.account.update-password') }}</button>
            
            </div>

            
          </div>

          <div class="ibox" v-if="isTwoFactorEnabled">
            <div class="ibox-title">
              <h5>{{ $t('profile.two-factor-authentication.title') }}</h5>
            </div>
            <div class="ibox-content">

              <div v-if="user.hasSecretKey">
                <div class="row m-sm">
                    <div class="col">
                      {{ $t('profile.two-factor-authentication.activated') }}
                    </div>
                </div>
                <div class="row m-sm">
                    <div class="col">
                      
                    </div>
                </div>
                
              </div>
      
              <SetupTotp v-if="!user.hasSecretKey"></SetupTotp>
            
            </div>

            
          </div>
        </div>
     
        <div class="col-md-8">
          <div class="ibox">
            <div class="ibox-title">
              <h5>{{ $t('profile.info.title') }}</h5>
            </div>
            <div class="ibox-content">
      
              <form role="form" name="update-profile" @submit="onProfileFormSubmit">
                <div class="form-group row">
                    <label for="updateFirstName" class="col-lg-3 col-form-label">{{ $t('profile.info.firstName') }}</label>
                    <div class="col-lg-9">
                      <input id="updateFirstName" type="text" :placeholder="$t('profile.info.firstName_placeholder')" class="form-control" v-model="form.firstName" />
                    </div>
                </div>
                <div class="form-group row">
                    <label for="updateMiddleName" class="col-lg-3 col-form-label">{{ $t('profile.info.middleName') }}</label>
                    <div class="col-lg-9">
                      <input id="updateMiddleName" type="text" :placeholder="$t('profile.info.middleName_placeholder')" class="form-control" v-model="form.middleName" />
                    </div>
                </div>
                <div class="form-group row">
                    <label for="updateLastName" class="col-lg-3 col-form-label">{{ $t('profile.info.lastName') }}</label>
                    <div class="col-lg-9">
                      <input id="updateLastName" type="text" :placeholder="$t('profile.info.lastName_placeholder')" class="form-control" v-model="form.lastName" />
                    </div>
                </div>
                <div v-if="profile.company" class="form-group row">
                  <label class="col-lg-3 col-form-label">{{ $t('profile.info.company') }}</label>
                  <div class="col-lg-9">
                    <input type="text" readonly class="form-control-plaintext" v-model="profile.company.name" />
                  </div>
                </div>
                <div v-if="profile.position" class="form-group row">
                  <label class="col-lg-3 col-form-label" for="position">{{$t('profile.info.position')}}</label>
                  <div class="col-lg-9">
                    <select id="position" class="form-control" v-model="form.position" required>
                      <option value="">{{$t('profile.info.position_select')}}</option>
                      <option v-for="(position, index) in positions" :key="index" :value="position">{{$t('profile.positions.'+position)}}</option>
                    </select>
                  </div>
                </div>
                <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">{{ $t('profile.info.save') }}</button>
              </form>
            
            </div>
          </div>
        </div>
      </div>

      <b-modal size="lg" ref="updateUserModal" hide-footer lazy>
        <UpdateUser v-on:user-updated="onUserUpdated" />
      </b-modal>

      <b-modal size="lg" ref="updatePasswordModal" hide-footer lazy>
        <UpdatePassword />
      </b-modal>

    </div>
  </section>
</template>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import PageTitle from '../panels/PageTitle.vue';
import SetupTotp from '../panels/authentication/SetupTotp.vue';
import UpdateUser from '../panels/authentication/UpdateUser.vue';
import UpdatePassword from '../panels/authentication/UpdatePassword.vue';
import * as api from '@fwk-client/utils/api';
import { messagesTypes, MessageTypes, authenticationTypes } from '@fwk-client/store/types';
import * as Ladda from 'ladda';

import { positions } from '../../types/common';

@Component({
  components: {
    PageTitle,
    SetupTotp,
    UpdateUser,
    UpdatePassword
  }
})
export default class Profile extends mixins(GenericPage) {

  isTwoFactorEnabled:boolean = false;
  isUpdateUserAllowed:boolean = true;
  isUpdatePasswordAllowed:boolean = true;

  positions = positions;

  get user() {
      if(this.$store.state.authentication.isLoggedIn) {
        return this.$store.state.authentication.user;
      }
      return null;
  }

  get profile() {
    return this.user.profile;
  }
  
  form = {
    firstName : this.profile.firstName,
    middleName : this.profile.middleName,
    lastName : this.profile.lastName,
    position: this.profile.position
  };

  laddaSubmit:Ladda.LaddaButton|null = null;

  mounted() {
    var button:HTMLButtonElement|null = document.querySelector( 'form[name=update-profile] button' );
    this.laddaSubmit = Ladda.create(button!);
  }

  onProfileFormSubmit(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "userID" : this.user._id,
      "profile": {
        "firstName" : this.form.firstName,
        "middleName" : this.form.middleName,
        "lastName" : this.form.lastName
      }
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaSubmit!.start();
    
    api.postAPI('/api/auth/profile/update', input, options).then((response:any) => {
      this.laddaSubmit!.stop();
      if(response.updated) {  
        // We update the user in the store
        this.$store.commit('authentication/' + authenticationTypes.mutations.UPDATE_USER, response.user);
      }
    });
  }

  onUpdateUserButtonClick() {
    // @ts-ignore
    this.$refs.updateUserModal.show()
  }

  onUpdatePasswordButtonClick() {
    // @ts-ignore
    this.$refs.updatePasswordModal.show()
  }

  onUserUpdated(user:any) {
    this.$store.commit('authentication/' + authenticationTypes.mutations.UPDATE_USER, user);
  }

}
</script>