import { render, staticRenderFns } from "./SetupTotp.vue?vue&type=template&id=e508ab28&scoped=true"
import script from "./SetupTotp.vue?vue&type=script&lang=ts"
export * from "./SetupTotp.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e508ab28",
  null
  
)

export default component.exports