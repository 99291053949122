<template>
  <validation-observer v-slot="{ invalid }" slim>  
    <form role="form" @submit="onFormSubmit" name="update-user">
        <validation-provider v-if="user.login != user.email" name="login" mode="eager" v-slot="{ errors, classes }" slim>
          <div :class="{'form-group':true, 'row':true, ...classes}">
            <label for="updateDetailsLogin" class="col-lg-3 col-form-label">{{ $t('profile.info.login') }}</label>
            <div class="col-lg-9">
              <input id="updateDetailsLogin" type="text" :placeholder="$t('profile.info.login_placeholder')" class="form-control" v-model="form.login" required>
              <span class="control-label form-text m-b-none" v-if="errors.length > 0">{{ errors[0] }}</span>
            </div>
          </div>
        </validation-provider>
        <validation-provider name="email" mode="eager" v-slot="{ errors, classes }" slim>
          <div :class="{'form-group':true, 'row':true, ...classes}">
            <label for="updateDetailsEmail" class="col-lg-3 col-form-label">{{ $t('profile.info.email') }}</label>
            <div class="col-lg-9">
              <input id="updateDetailsEmail" type="email" :placeholder="$t('profile.info.email_placeholder')" class="form-control" v-model="form.email" required>
              <span class="control-label form-text m-b-none" v-if="errors.length > 0">{{ errors[0] }}</span>
            </div>
          </div>
        </validation-provider>

        <div class="hr-line-dashed"></div>

        <validation-provider name="currentPassword" mode="aggressive" ref="currentPasswordProvider" v-slot="{ errors, classes }" slim>
          <div :class="{'form-group':true, 'row':true, ...classes}">
            <label for="updateDetailsCurrentPassword" class="col-lg-3 col-form-label">{{ $t('profile.info.currentPassword') }}</label>
            <div class="col-lg-9">
              <input id="updateDetailsCurrentPassword" type="password" :placeholder="$t('profile.info.currentPassword_placeholder')" autocomplete="new-password" class="form-control" v-model="form.currentPassword" required>
              <span class="control-label form-text m-b-none" v-if="errors.length > 0">{{ errors[0] }}</span>
            </div>
          </div>
        </validation-provider>
        <button :disabled="invalid" class="btn btn-primary ladda-button update-details" data-style="zoom-in" type="submit">{{ $t('profile.info.save') }}</button>
    </form>
  </validation-observer>
</template>

<style scoped>
  .sk-spinner {
    height: 33px;
    padding-top: 7px;
    text-align: left;
    margin: 0;
  }
</style>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';
import $ from 'jquery';
import { messagesTypes, MessageTypes } from '@fwk-client/store/types';
import * as Ladda from 'ladda';

@Component({
  components: { }
})
export default class Update extends Vue {

  get user() {
      if(this.$store.state.authentication.isLoggedIn) {
        return this.$store.state.authentication.user;
      }
      return null;
  }

  form = {
    login: this.user.login,
    email: this.user.email,
    currentPassword : '',
  };

  laddaSubmit:Ladda.LaddaButton|null = null;

  created() {
    
  }

  mounted() {
    var updateDetailsButton:HTMLButtonElement|null = document.querySelector( 'form[name=update-user] button.ladda-button.update-details' );
    this.laddaSubmit = Ladda.create(updateDetailsButton!);
  }

  onFormSubmit(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "login" : this.user.login != this.user.email ? this.form.login : undefined, // We send the login only if it is different from the email
      "email" : this.form.email,
      "currentPassword" : this.form.currentPassword
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaSubmit!.start();
    
    api.postAPI('/api/auth/user/update', input, options).then((response:any) => {
      if(response.updated) {  
        var user = response.user;
        // We emit event as user is updated
        this.$emit('user-updated', user);

        // We update the form based on values returned by backend (in case not updated)
        this.form.login = user.login;
        this.form.email = user.email;
      }

       this.laddaSubmit!.stop();
    });
  }
  
}
</script>