<template>

  <div>

      <img v-if="qrCodeSrc" :src="qrCodeSrc" />

      <RequestTotp></RequestTotp>

  </div>
      

</template>

<style scoped>

</style>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Watch, Prop } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import RequestTotp from './RequestTotp.vue';
import * as api from '@fwk-client/utils/api';

@Component({
  components: {
    RequestTotp
  }
})
export default class SetupTotp extends Vue {

  qrCodeSrc: string | null = null;

  created() {
    this.generateQRCode();
  }

  generateQRCode() {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }
    api.getAPI('/api/auth/generate-totp', options).then((response:any) => {
      if(response.qrCodeSrc) {  
        this.qrCodeSrc = response.qrCodeSrc;
      }
    });
  }

}
</script>