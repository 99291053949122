<template>
  <validation-observer v-slot="{ invalid }" slim>  
    <form role="form" @submit="onFormSubmit" name="update-password">
       <validation-provider name="currentPassword" mode="aggressive" ref="currentPasswordProvider" v-slot="{ errors, classes }" slim>
          <div :class="{'form-group':true, 'row':true, ...classes}">
            <label for="updateDetailsCurrentPassword" class="col-lg-3 col-form-label">{{ $t('profile.info.currentPassword') }}</label>
            <div class="col-lg-9">
              <input id="updateDetailsCurrentPassword" type="password" :placeholder="$t('profile.info.currentPassword_placeholder')" autocomplete="new-password" class="form-control" v-model="form.currentPassword" required>
              <span class="control-label form-text m-b-none" v-if="errors.length > 0">{{ errors[0] }}</span>
            </div>
          </div>
        </validation-provider>

        <div class="hr-line-dashed"></div>

        <div class="form-group row">
            <label for="updateDetailsPassword" class="col-lg-3 col-form-label">{{ $t('profile.info.newPassword') }}</label>
            <div class="col-lg-9">
              <input id="updateDetailsPassword" type="password" :placeholder="$t('profile.info.newPassword_placeholder')" autocomplete="new-password" class="form-control" v-model="form.password">
            </div>
        </div>
        <div class="form-group row">
            <label for="updateDetailsConfirmPassword" class="col-lg-3 col-form-label">{{ $t('profile.info.confirmNewPassword') }}</label>
            <div class="col-lg-9">
              <input id="updateDetailsConfirmPassword" type="password" :placeholder="$t('profile.info.confirmNewPassword_placeholder')" autocomplete="new-password" class="form-control" v-model="form.confirmPassword">
            </div>
        </div>
       
        <button :disabled="invalid" class="btn btn-primary ladda-button update-details" data-style="zoom-in" type="submit">{{ $t('profile.info.save') }}</button>
    </form>
  </validation-observer>
</template>

<style scoped>
  .sk-spinner {
    height: 33px;
    padding-top: 7px;
    text-align: left;
    margin: 0;
  }
</style>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';
import $ from 'jquery';
import { messagesTypes, MessageTypes } from '@fwk-client/store/types';
import * as Ladda from 'ladda';

@Component({
  components: { }
})
export default class UpdatePassword extends Vue {

  form = {
    currentPassword : '',
    password : '',
    confirmPassword : ''
  };

  laddaSubmit:Ladda.LaddaButton|null = null;

  created() {
    
  }

  mounted() {
    var updateDetailsButton:HTMLButtonElement|null = document.querySelector( 'form[name=update-password] button.ladda-button.update-details' );
    this.laddaSubmit = Ladda.create(updateDetailsButton!);
  }

  onFormSubmit(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "currentPassword" : this.form.currentPassword,
      "password" : this.form.password,
      "confirmPassword" : this.form.confirmPassword
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaSubmit!.start();
    
    api.postAPI('/api/auth/user/update', input, options).then((response:any) => {
      if(response.updated) {  
        // We reset the field
        this.form.currentPassword = "";
        this.form.password = "";
        this.form.confirmPassword = "";
        // @ts-ignore
        this.$refs.currentPasswordProvider.reset();
      }

       this.laddaSubmit!.stop();
    });
  }
  
}
</script>